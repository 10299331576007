.individual-image {
  height: 100;
  width: 100;
}

.inner-image {
  display: flex;
  margin-left: 50% !important;
}

.recipe-detail-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
}

@media (max-width: 750px) {
  .inner-image {
    margin-left: 0 !important;
  }

  .recipe-desc {
    font-size: 12px;
  }

  .recipe-ing {
    font-size: 14px;
  }
}
