.recipe-container {
  display: flex;
  flex-direction: column;
  margin: 5% 20%;
}

@media only screen and (max-width: 750px) {
  .recipe-container {
    margin: 1%;
  }
}
