.recipe-sort {
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 750px) {
  .recipe-sort {
    flex-direction: column;
  }
}
